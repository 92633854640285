<template>
  <div class="mt-3">
    <div class="deposit-title mt-5 mt-md-1">
      <p>Способы оплаты</p>
      <p v-if="withdrawType =='vip'">Уважаемый клиент, все VIP выводы будут отправлены в течение часа.</p>
    </div>
    <div class="row mt-2"
         v-if="withdrawMethods.card.length || withdrawMethods.sbp.length || withdrawMethods.wallet.length">
      <div class="col-12 col-lg-4 method-block">
        <div class="d-none d-md-block">
          <div
              v-if="withdrawMethods.card.length"
              @click="blockMethod(cardMethod)"
          >
            <PaymentMethod
                :methodPay="cardMethod"
                :activeMethod="withdrawSelectedMethod.id ? withdrawSelectedMethod.id : cardMethod.id"
                ref="cardwithdrawMethods"
            />
          </div>
          <div
              v-if="withdrawMethods.sbp.length"
              @click="blockMethod(sbpMethod)"
          >
            <PaymentMethod
                :methodPay="sbpMethod"
                :activeMethod=withdrawSelectedMethod.id
                ref="withdrawMethods"
            />
          </div>
          <div
              v-if="withdrawMethods.usdt.length"
              @click="blockMethod(usdtMethod)"
          >
            <PaymentMethod
                :methodPay="usdtMethod"
                :activeMethod=withdrawSelectedMethod.id
                ref="withdrawMethods"
            />
          </div>
          <div
              v-for=" (method, index) in withdrawMethods.wallet" :key="index"
              @click="changeMethod(method)"
          >
            <PaymentMethod
                :methodPay="method"
                :activeMethod=withdrawSelectedMethod.id
                ref="withdrawMethods"
            />
          </div>
        </div>
        <div class="d-block d-md-none">
          <div class="select-block-mobile position-relative w-100 py-0 px-4" v-if="isCardAndSbp">
            <div class="selected-option" @click="toggleDropdown">
              <img :src="selectedOption.image" :alt="selectedOption.label" :data-src="selectedOption.image"
                   class="selected-img">
              <span>{{ selectedOption.label }}</span>
              <span class="dropdown-arrow ml-auto"
                    :class="{ 'fa fa-angle-up': isDropdownOpen, 'fa fa-angle-down': !isDropdownOpen }"></span>
            </div>
            <div class="dropdown w-100" v-show="isDropdownOpen">
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="withdrawMethods.card.length"
                  @click="blockMethod(cardMethod)"
              >
                <img :src="getPictureUrl(cardMethod)" :data-src="getPictureUrl(cardMethod)" class="dropdown-img mr-3">
                <span>{{ cardMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="withdrawMethods.sbp.length"
                  @click="blockMethod(sbpMethod)"
              >
                <img :src="getPictureUrl(sbpMethod)" :data-src="getPictureUrl(sbpMethod)" class="dropdown-img mr-3">
                <span>{{ sbpMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="withdrawMethods.usdt.length"
                  @click="blockMethod(usdtMethod)"
              >
                <img :src="getPictureUrl(usdtMethod)" :data-src="getPictureUrl(usdtMethod)" class="dropdown-img mr-3">
                <span>{{ usdtMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-for=" (method, index) in withdrawMethods.wallet" :key="index"
                  @click="changeMethod(method)"
              >
                <img :src="getPictureUrl(method)" :data-src="getPictureUrl(method)" class="dropdown-img mr-3">
                <span>{{ method.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 p-md-X">
        <div>
          <div class="deposit-title mt-5 mt-md-1">
            <p>Выберите оператора {{ withdrawSelectedMethod.method_type }}</p>
          </div>
          <div class="d-none d-md-block">
            <div>
              <div class="row"
                   v-if="!withdrawSelectedMethod.id || withdrawSelectedMethod.method_type == 'card'">
                <div class="col-12 col-md-6"
                     v-for=" (method, index) in withdrawMethods.card" :key="index"
                     @click="changeMethod(method)">
                  <PaymentMethod
                      :methodPay="method"
                      :activeMethod=withdrawSelectedMethod.id
                      ref="cards"
                  />
                </div>
              </div>

              <div class="row" v-else-if="withdrawSelectedMethod.method_type == 'sbp'">
                <div class="col-12 col-md-6"
                     v-for=" (method, index) in withdrawMethods.sbp" :key="index"
                     @click="changeMethod(method)">
                  <PaymentMethod
                      :methodPay="method"
                      :activeMethod=withdrawSelectedMethod.id
                      ref="sbp"
                  />
                </div>
              </div>
              <div class="row" v-else-if="withdrawSelectedMethod.method_type == 'crypto'">
                <div class="col-12 col-md-6"
                     v-for=" (method, index) in withdrawMethods.usdt" :key="index"
                     @click="changeMethod(method)">
                  <PaymentMethod
                      :methodPay="method"
                      :activeMethod=withdrawSelectedMethod.id
                      ref="crypto"
                  />
                </div>
              </div>
              <div class="row rowWallet" v-else>
                <div class="col-12 col-md-6 selected-method">
                  <PaymentMethod
                      :methodPay="withdrawSelectedMethod"
                      :activeMethod=withdrawSelectedMethod.id
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-block d-md-none">
            <div v-if="!isCardAndSbp" class="d-block d-md-none">
              <div class="col-12 col-md-6"
                   v-if="withdrawMethods.card.length"
                   @click="blockMethod(cardMethod)">
                <PaymentMethod
                    :methodPay="cardMethod"
                    :activeMethod="withdrawSelectedMethod.id"
                    ref="cardwithdrawMethods"
                />
              </div>
              <div class="col-12 col-md-6"
                   v-if="withdrawMethods.sbp.length"
                   @click="blockMethod(sbpMethod)">
                <PaymentMethod
                    :methodPay="sbpMethod"
                    :activeMethod=withdrawSelectedMethod.id
                    ref="withdrawMethods"
                />
              </div>

              <div class="col-12 col-md-6"
                   v-for=" (method, index) in withdrawMethods.wallet" :key="index"
                   @click="changeMethod(method)">
                <PaymentMethod
                    :methodPay="method"
                    :activeMethod=withdrawSelectedMethod.id
                    ref="sbp"
                />
              </div>
            </div>
            <div v-else>
              <div class="row"
                   v-if="!withdrawSelectedMethod.id || withdrawSelectedMethod.method_type == 'card'">
                <div class="col-12 col-md-6"
                     v-for=" (method, index) in withdrawMethods.card" :key="index"
                     @click="changeMethod(method)">
                  <PaymentMethod
                      :methodPay="method"
                      :activeMethod=withdrawSelectedMethod.id
                      ref="cards"
                  />
                </div>
              </div>

              <div class="row" v-else-if="withdrawSelectedMethod.method_type == 'sbp'">
                <div class="col-12 col-md-6"
                     v-for=" (method, index) in withdrawMethods.sbp" :key="index"
                     @click="changeMethod(method)">
                  <PaymentMethod
                      :methodPay="method"
                      :activeMethod=withdrawSelectedMethod.id
                      ref="sbp"
                  />
                </div>
              </div>
              <div class="row" v-else-if="withdrawSelectedMethod.method_type == 'crypto'">
                <div class="col-12 col-md-6"
                     v-for=" (method, index) in withdrawMethods.usdt" :key="index"
                     @click="changeMethod(method)">
                  <PaymentMethod
                      :methodPay="method"
                      :activeMethod=withdrawSelectedMethod.id
                      ref="sbp"
                  />
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-12 col-md-6 selected-method">
                  <PaymentMethod
                      :methodPay="withdrawSelectedMethod"
                      :activeMethod=withdrawSelectedMethod.id
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="cart-block">
            <b-form
                v-if="withdrawSelectedMethod.name !== '' &&  withdrawSelectedMethod.id !== 1 && withdrawSelectedMethod.id !== 2 && withdrawSelectedMethod.id !== 3"
                class="withdraw-form my-4 col-12">
              <div class="row w-100 mx-0">
                <div v-if="withdrawSelectedMethod.name" class="chosen-method-info">
                  <span class="hint-amount gray-color">Минимальная сумма выплаты {{ withdrawSelectedMethod.min_amount }}
                    руб.</span><br>

                  <span class="hint-amount gray-color" v-if="withdrawSelectedMethod.type !== 'android'">
                    Максимальная сумма выплаты {{ withdrawSelectedMethod.max_amount }} руб. за один перевод.<br>
                  </span>

                  <span class="hint-amount gray-color" v-if="withdrawSelectedMethod.type !== 'android' && withdrawSelectedMethod.time && !withdrawSelectedMethod.is_vip">
                    до {{ selectTime(withdrawSelectedMethod.time) }}
                  </span>
                  <span class="hint-amount gray-color" v-else-if = "withdrawSelectedMethod.is_vip">
                    до 1 ч.
                  </span>
                  <span class="hint-amount gray-color" v-else>
                    Вывод происходит в протяжении пару минут.
                  </span>

                  <span class="hint-amount gray-color"><br><br>
                    Уважаемый Игрок, максимальная сумма вывода за день составляет 50.000руб! Остаток выигрыша можете
                    выводить в последующие дни!
                  </span><br>

                  <span class="hint-amount">
                    {{ withdrawSelectedMethod.add_info }}
                  </span><br><br>

                </div>

                <div class="input-group mb-2 mb-lg-4">
                  <div class="input-group">
                    <div class="col-12 px-0">
                      <input id="withdraw-amount" v-model="payload.amount" class="form-control main-input" type="text"
                             :min="withdrawSelectedMethod.min_amount" :max="withdrawSelectedMethod.max_amount"
                             placeholder="Сумма в рублях" v-on:keyup="keyUp" required>
                    </div>
                    <span class="hint-amount" v-if="withdrawinfo" style="margin-top: 10px">{{ withdrawinfotext }}</span>
                  </div>
                </div>

                <div class="input-group mb-2 mb-lg-4" v-if="withdrawSelectedMethod.type == 'auris_ALL'">
                  <div class="input-group">
                    <div class="col-12 px-0">
                      <input id="withdraw-bank" v-model="payload.bank" class="form-control main-input" type="text"
                             :min="withdrawSelectedMethod.min_amount" :max="withdrawSelectedMethod.max_amount"
                             placeholder="Введите название банка">
                    </div>
                  </div>
                </div>

                <div class="input-group mb-2 mb-lg-4" v-if="withdrawSelectedMethod.type == 'android'">
                  <div class="input-group">
                    <div class="col-12 px-0">
                      <input id="withdraw-bank" v-model="payload.bank" class="form-control main-input" type="text"
                             placeholder="Введите Android логин">
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2 mb-lg-4" v-if="withdrawSelectedMethod.type !== 'android'">
                  <div class="input-group">
                    <div class="col-12 px-0">
                      <input v-model="payload.recipient" v-mask="getSelectedMethodMask" class="form-control main-input"
                             type="text"
                             :placeholder="withdrawSelectedMethod.name === '' ? 'Выбеите способ выплаты' : 'Введите ' + withdrawSelectedMethod.requisites_title">
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2 mb-lg-4" v-if="withdrawSelectedMethod.additional_info.length">
                  <div class="input-group">
                    <div class="col-12 px-0">
                      <select v-model="payload.bank" id="selectBank" class="form-control main-input main-select">
                        <option :value="null" disabled selected>Выбери банк</option>
                        <option v-for="(method, index) in withdrawSelectedMethod.additional_info"
                                :key="index"
                                :value="method.code">
                          {{ method.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 tip" v-if="tipData && tipData.use && tipData.use === true ">
                  <div class="tip-block">
                    <div class="text-block">
                      <p>Хочу оставить на чай</p>
                    </div>
                    <div class="tip-type-block">
                      <div class="position-relative" v-for="(data, index) in tipTypesData" :key="index">
                        <label for="finButton">
                          <img :src="data.src" class="fin-icon tip-type"
                               :class="{ 'active-type': index === activeType }"
                               @click="tipTypeAction(data.value,index)">
                        </label>
                        <div class="text-center name-types">
                          <span>{{ data.label }}</span>
                        </div>
                        <div class="check-block d-flex justify-content-center">
                          <svg class="checkmark" width="40" height="40" viewBox="0 0 108 108" fill="none"
                               xmlns="http://www.w3.org/2000/svg" :class="{ 'active-check': index === activeType }">
                            <path class="checkmark__check"
                                  d="M24 51.6739C25.8629 51.6739 35.5417 62.1638 40.8583 68.0867C42.7759 70.223 46.0818 70.3683 48.1455 68.3727C57.6301 59.2012 81.8373 36 84 36"
                                  stroke="#098a1b" stroke-width="10" stroke-linecap="round"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="tip-amount-block">
                      <div class="smaylik-amount-block">
                        <div class="amout-smayl" v-for="(data, index) in tipAmountData" :key="index">
                          <label for="amountBtn10">
                            <img :src="data.src" class="amount-icon"
                                 @click="tipAmountAction(tipData.amounts[index],index)"
                                 :class="{ 'active-amount': index === activeAmount }">
                          </label>
                        </div>
                      </div>
                      <div class="samyl-text-block">
                        <span>Выберите сумму для чаевых от {{ tipData.amounts[0] }}  руб․</span>
                      </div>
                      <div>
                        <input v-model="tipAmount"
                               v-on:keyup="tipAmountkeyUp"
                               placeholder="Сумма на чай"
                               class="form-control input-md rect tip-amount-val">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row w-100 mx-0">
                  <Alert :message="formResponse"></Alert>
                </div>
                <b-row class="mt-2 w-100 mx-0">
                  <div class="col-12 col-lg-7 mx-auto px-0">
                    <div class="green-btn-bg">
                      <button name="openModal" class="btn red-btn w-100 text-white" :disabled="isSubmitting"
                              @click.stop.prevent="openModal()">
                        Вывести деньги
                      </button>
                    </div>
                  </div>
                </b-row>
              </div>
            </b-form>

          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-primary">
        <div class="col-12 resp-loader loading">
          <i class="fa fa-spinner fa-spin fa-fw"/>
        </div>
      </div>
    </div>
    <b-modal v-if="withdrawSelectedMethod" id="confirmWithdrawModal" v-model="confirmWithdrawModal" size="md"
             modal-class="main-modal" :hide-header="true" footer-border-variant="gray">
      <div class="col-12 px-0 text-center">
        <span class=""> {{ hint }} </span>

        <div class="row mx-0 my-3">
          <Alert :message="popupMessages"></Alert>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }" class="border-0">
        <b-button name="makeWithdraw" size="sm" class="btn red-btn text-white" @click.prevent="makeWithdraw()">
          Вывести
        </b-button>
        <b-button name="cancel" size="sm" class="gray-btn" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>

</template>

<script>
import Alert from '@/components/Alert'
import PaymentMethod from './PaymentMethod.vue'

const moneyIcon = require('@/../public/images/icons/payment/payout.png')
export default {
  props: ['withdrawType'],
  name: 'Withdraw',
  data() {
    return {
      isCardAndSbp: false,
      isDropdownOpen: false,
      selectedOption: {
        label: '',
        image: ''
      },
      currentPath: null,
      moneyIcon,
      tipType: '',
      tipAmount: '',
      tipTypesData: [
        {src: require('@/../public/images/icons/tips/fin1.png'), value: 1, label: 'Фин.Отдел'},
        {src: require('@/../public/images/icons/tips/meneg1.png'), value: 2, label: 'Менеджер'},
      ],
      tipAmountData: [
        {src: require('@/../public/images/icons/tips/10.png')},
        {src: require('@/../public/images/icons/tips/50.png')},
        {src: require('@/../public/images/icons/tips/100.png')},
        {src: require('@/../public/images/icons/tips/no-tip.png')},
      ],
      activeAmount: '',
      activeType: '',
      payload: {
        recipient: '',
        amount: '',
        name: '',
        bank: null
      },
      withdrawSelectedMethod: {
        id: null,
        name: '',
        mask: '',
        min_amount: 0,
        max_amount: 0,
        type: '',
        time: '',
        add_info: '',
        picture: '',
        card_picture: '',
        method_type: ''
      },
      cardMethod: {
        id: 2,
        name: 'Карта',
        method_type: 'card',
        card_picture: require('@/../public/images/icons/karta.svg')
      },
      sbpMethod: {
        id: 1,
        name: 'СБП',
        method_type: 'sbp',
        card_picture: require('@/../public/images/icons/sbp.svg')
      },
      usdtMethod: {
        id: 3,
        name: 'USDT',
        method_type: 'crypto',
        card_picture: require('@/../public/images/icons/cripto.svg')
      },
      isSubmitting: true,
      modalConfirmed: false,
      confirmWithdrawModal: false,
      formResponse: {},
      popupMessages: {},
      hint: '',
      withdrawinfo: false,
      withdrawinfotext: ''
    }
  },
  components: {
    Alert,
    PaymentMethod
  },
  computed: {
    getSelectedMethodMask() {
      if (Array.isArray(this.withdrawSelectedMethod.mask) && this.withdrawSelectedMethod.mask.length > 0) {
        if (this.withdrawSelectedMethod.mask.some(item => item !== '')) {
          return this.withdrawSelectedMethod.mask.join(',').split(',');
        }
      } else if (typeof this.withdrawSelectedMethod.mask === 'string' && this.withdrawSelectedMethod.mask !== '') {
        return this.withdrawSelectedMethod.mask;
      }

      return "";

    },
    withdrawMethods() {
      return this.$store.getters.getWithdrawMethods[this.withdrawType]
    },
    userData() {
      return this.$store.getters.userData
    },
    isMobile() {
      return this.$_config.isMobile()
    },
    tipData() {
      return this.$store.getters.getTipData
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getPictureUrl(url) {
      if (url.card_picture) {
        return url.card_picture;
      } else {
        return this.$_config.baseUrl + (url.picture ?? url.img)
      }
    },
    selectTime(time) {
      if (time > 59) {
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        let formatTime = hours + " ч. ";
        if (minutes !== 0) {
          formatTime += minutes + " мин.";
        }
        return formatTime;
      } else {
        return time + " мин.";
      }
    },
    changeMethod(method) {
      this.isCardAndSbp = true;
      this.withdrawinfo = false
      if (!this.userData) {
        this.$root.$emit('bv::hide::modal', 'paymentModal')
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }
      this.isDropdownOpen = false;
      this.isSubmitting = true;
      this.payload.amount = '';
      this.payload.bank = null;
      this.payload.recipient = '';
      this.tipType = '';
      this.tipAmount = '';
      this.activeAmount = '',
          this.activeType = '',
          this.withdrawSelectedMethod = method
      this.withdrawSelectedMethod.picture = method.img ?? method.picture
      this.withdrawSelectedMethod.id = method.id
      this.formResponse = {}
      if (method.method_type == 'card') {
        this.selectedOption.label = this.cardMethod.name
        this.selectedOption.image = this.cardMethod.card_picture
      } else if (method.method_type == 'sbp') {
        this.selectedOption.label = this.sbpMethod.name
        this.selectedOption.image = this.sbpMethod.card_picture
      } else if (method.method_type == 'crypto') {
        this.selectedOption.label = this.usdtMethod.name
        this.selectedOption.image = this.usdtMethod.card_picture
      } else {
        this.selectedOption.label = this.withdrawSelectedMethod.name
        this.selectedOption.image = this.$_config.baseUrl + this.withdrawSelectedMethod.picture
      }
      setTimeout(() => {
        const amountInput = document.getElementById('withdraw-amount')
        if (amountInput) {
          amountInput.focus()
        }
      }, 100)

    },

    blockMethod(key) {
      this.isCardAndSbp = true;
      this.isDropdownOpen = false;
      this.withdrawSelectedMethod = key
      this.selectedOption.label = key.name
      this.selectedOption.image = key.picture ?? key.card_picture
    },
    tipAmountkeyUp() {
      this.isSubmitting = false
    },
    makeWithdraw() {
      if (!this.userData) {
        return
      }
      this.formResponse = {status: 'loading'}
      this.popupMessages = {status: 'loading'}
      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/newWithdraw',
          {
            withdraw_amount: this.payload.amount,
            withdraw_requisites: this.payload.recipient,
            method: this.withdrawSelectedMethod.type,
            bank: this.payload.bank,
            tipType: this.tipType,
            tipAmount: this.tipAmount
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      ).then(({data}) => {
        if (data.status === 'ok') {
          this.popupMessages = data
          this.formResponse = {}
          this.payload.amount = null
          this.payload.recipient = null

          this.isSubmitting = true
          this.tipType = '';
          this.tipAmount = '';
          this.activeAmount = '',
              this.activeType = '',
              setTimeout(() => {
                this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
              }, 4000)
        } else {
          this.confirmWithdrawModal = false
          this.formResponse = data
        }
        this.isSubmitting = false
      }).catch(err => {
        console.log(err)
      })
      return true
    },
    openModal() {
      this.formResponse = {}
      this.popupMessages = {}
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }

      if (!this.withdrawSelectedMethod.name) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста выберите платежную систему'}
        return false
      }

      if (!this.payload.amount || this.payload.amount != parseFloat(this.payload.amount)) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста, укажите правильую сумму'}
        return false
      }

      if (this.withdrawSelectedMethod.type == "auris_ALL") {
        var regs = /^[A-Za-z0-9а-яА-Я]{3,30}$/
        if (!regs.test(this.payload.bank)) {
          this.formResponse = {status: 'error', msg: 'Название банка - неправильный формат'}
          return false
        }
      }

      if (this.payload.amount < this.withdrawSelectedMethod.min_amount) {
        this.formResponse = {
          status: 'error',
          msg: 'Минимальная сумма для выбранного метода  ' + this.withdrawSelectedMethod.min_amount
        }
        return false
      }

      if (this.payload.amount > this.withdrawSelectedMethod.max_amount) {
        this.formResponse = {
          status: 'error',
          msg: 'Максимальная сумма для снятия ' + this.withdrawSelectedMethod.max_amount
        }
        return false
      }

      if (!this.payload.recipient && this.withdrawSelectedMethod.name != 'Аndroid') {
        this.formResponse = {status: 'error', msg: 'Заполните реквизты.'}
        return false
      }

      if (this.withdrawSelectedMethod.type == "android" && !this.payload.bank) {
        this.formResponse = {status: 'error', msg: 'Пожалуйста, укажите Android логин'}
        return false
      }

      this.confirmWithdrawModal = true

      if (this.payload.recipient) {
        this.hint = 'Подтвердите вывод на ' + this.payload.recipient + ' на сумму ' + this.payload.amount + 'Руб'
      } else {
        this.hint = 'Подтвердите вывод на сумму ' + this.payload.amount + 'Руб'
      }
    },
    keyUp(evt) {
      this.withdrawinfo = false
      if (this.withdrawSelectedMethod.type == "visacardpercent" && evt.target.value >= this.withdrawSelectedMethod.min_amount) {
        this.withdrawinfo = true
        this.withdrawinfotext = "Поступят " + (evt.target.value - 50) + " руб, 50 руб комиссия"
      }
    },

    tipTypeAction(type, index) {
      this.tipType = type
      this.activeType = index
    },

    tipAmountAction(amount, index) {
      this.isSubmitting = false
      this.tipAmount = amount
      this.activeAmount = index
    },
    clickOnFirstMethod() {
      const firstMethod = this.$refs.cardwithdrawMethods;
      if (firstMethod) {
        firstMethod.$el.click();
      }
    }

  },
  mounted() {
    this.$store.dispatch('fetchAvailableWithdrawMethods')
    this.$store.dispatch('fetchWithdrawHistory')
    this.selectedOption.label = this.cardMethod.name
    this.selectedOption.image = this.cardMethod.card_picture
  }
}
</script>

<style scoped>
.method-block {
  height: auto;
  border-right: 2px solid rgb(143, 141, 141);

}

.selected-method {
  width: calc(50% - 6px);
}

.deposit-amount-btn,
.col-form-label,
.bonus-text,
.hint-amount,
.samyl-text-block,
.name-types {
  font-size: 12px;
}

.main-input {
  background: #1c2028;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  height: 40px;
  padding-left: 35px;
}

.main-input:hover,
.main-input:focus {
  background: #1c2028;
  overflow: hidden;
  box-shadow: none;
}

.tip {
  display: flex;
  justify-content: center;
}

.tip-block {
  width: 300px;
}


.fin-icon{
  cursor: pointer;
  transition: fill 0.3s;
  border-radius: 50%;
  object-fit: contain;
}

.samyl-text-block {
  text-align: center;
}

.text-block {
  margin-top: 20px;
  font-size: 18px;
}

.mange span {
  margin-right: 20px;
}

.tip-type-block {
  height: 70px;
  display: flex;
  justify-content: space-around;
}

.tip-type {
  width: 50px;
  height: 50px;
  transition: width 0.5s, height 0.5s;
}

.active-type {
  width: 60px;
  height: 60px;
}

.smaylik-amount-block {
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.text-block p {
  text-align: center;
}

.amount-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  transition: width 0.5s, height 0.5s;
}

.active-amount {
  width: 50px;
  height: 50px;
}

.tip-amount-block {
  margin-top: 30px;
}

.tip-amount-val {
  margin-top: 30px;
  margin-bottom: 30px;
}

.checkmark {
  display: none;
}

.active-check {
  display: block;
}

.check-block {
  position: absolute;
  bottom: -25px;
  right: -40px;
}

.checkmark__check {
  stroke-dasharray: 412;
  stroke-dashoffset: 412;
  animation: anim 1s ease forwards 0.3s;
}

@keyframes anim {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 992px) {
  .method-block {
    overflow: unset;
    padding: 0;
  }
  .selected-option {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }

  .selected-img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #1c2028;
    border: 1px solid #333946;
    border-radius: 5px;
    box-shadow: 1px 3px 3px -2px #20242d;
    color: #aab0bd;
  }
}

@media (max-width: 430px) {
  .withdraw-form {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
